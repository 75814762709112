import React, { useState } from 'react'
import Header from './../../Layout/HeaderMenu'
import Footer from './../../Layout/Footer'
import PageTitle from './../../Layout/PageTitle'

import pic1 from './../../../images/our-team/pic1.jpg'
import pic2 from './../../../images/our-team/pic2.jpg'
import pic3 from './../../../images/our-team/pic3.jpg'
import pic4 from './../../../images/our-team/pic4.jpg'
// import pic6 from './../../../images/our-team/pic6.jpg'
import pic5 from './../../../images/our-team/pic5.jpg'
import pic7 from './../../../images/our-team/pic7.jpg'
import pic8 from './../../../images/our-team/pic8.jpg'
import pic12 from './../../../images/our-team/pic12.jpg'
import pic13 from './../../../images/our-team/pic13.jpg'
import pic14 from './../../../images/our-team/pic14.jpg'
import pic15 from './../../../images/our-team/pic15.jpg'

import { teamData } from './data'

const teamBlog = [
  { images: pic1, name: 'Engr Msiska', id: 1 },
  { images: pic3, name: 'Engr Okoro', id: 2 },
  { images: pic2, name: 'Mr Abraham', id: 3 },
  { images: pic4, name: 'Mr Luka Rwang Dashwei', id: 4 },
  { images: pic5, name: 'Mr McCarthy', id: 5 },
  { images: pic7, name: 'Engr Azu', id: 6 },
  { images: pic8, name: 'Col Joseph Miner RTD', id: 7 },
  { images: pic12, name: 'Engr. Yimba Terungwa Emmanuel', id: 8 },
  { images: pic13, name: 'Engr. Ayansola', id: 9 },
  { images: pic14, name: 'Sunday Samuel kargwak', id: 10 },
  { images: pic15, name: 'Engr.Muhammad Sani Yahaya', id: 11 },
]

const TeamDetails = ({
  match: {
    params: { ID },
  },
}) => {
  const [pageData] = useState(teamData.find((data) => data.id === Number(ID)))
  const [pageImage] = useState(teamBlog.find((data) => data.id === Number(ID)))

  return (
    <>
      <Header />
      <div className='page-content bg-white'>
        <div
          className='dlab-bnr-inr overlay-primary'
          style={{ backgroundImage: 'url(' + pageImage.images + ')' }}
        >
          <PageTitle motherMenu='' activeMenu='profile' />
        </div>
        <div className='content-block'>
          <div className='section-full content-inner-2'>
            <div className='container'>
              <div className='section-head text-black text-center'>
                <h2 className='box-title m-tb0'>
                  {pageData.name}
                  <span className='bg-primary'></span>
                </h2>
                <h4 className='text-gray-dark'>{pageData.title}</h4>
              </div>
            </div>

            <div className='container'>
              <div className='row '>
                <div className='col-lg-12 col-md-12'>
                  <div className='abuot-box left row m-lr0 '>
                    <div className='col-lg-4'>
                      <div className='about-img'>
                        <img src={pageImage.images} data-tilt alt='' />
                      </div>
                    </div>
                    <div className='col-lg-8'>
                      <div>
                        {pageData.profile.map(data =>
                          <p>{data}</p>
                        )}
                      </div>
                      <div className='row '>
                        <div className='col-lg-12 col-md-12'>
                          <p>{pageData?.additional}</p>
                          {pageData?.additionalData &&
                            pageData?.additionalData.map((data) => {
                              return (
                                <ul>
                                  <li>{data}</li>
                                </ul>
                              )
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default TeamDetails