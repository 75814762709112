import React from 'react'
import Header from '../../Layout/HeaderMenu'
import Footer from '../../Layout/Footer'
import PageTitle from '../../Layout/PageTitle';
import axios from 'axios';

const NewsDetails = ({
    match: {
        params: { ID },
    },
}) => {
    const [fetchedNews, setFetchNews] = React.useState(null)
    React.useEffect(() => {
        const getData = async () => {
            const response = await axios.get(`https://nesco-api-il7ir.ondigitalocean.app/api/v1/news/${ID}`);
            //  const response = await axios.get(`https://nesco-api.herokuapp.com/api/v1/news/${ID}`);
            setFetchNews(response.data)
        };

        getData()
    }, [ID])

    return (
        <>
            <Header />
            <div className="page-content bg-white">
                <div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: "url(" + fetchedNews?.data?.cover_image + ")" }}>
                    <PageTitle motherMenu='News Details' activeMenu='News Details' />
                </div>
                <div className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-xl-12">
                                {
                                    fetchedNews?.data ?
                                        <>
                                            <div className="blog-post blog-single">
                                                <div className="dlab-post-title text-center">
                                                    <h3 className="post-title m-t0 ">{fetchedNews?.data.title}</h3>
                                                </div>
                                                <div dangerouslySetInnerHTML={{ __html: fetchedNews?.data.content }} />
                                            </div>
                                        </> : <>Cannot get news, try again later</>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default NewsDetails;



// <div className="dlab-post-title">
//                                             <h5 className="post-title m-t0 ">
//                                             CORE FUNCTIONS
//                                             </h5>
//                                         </div>
//                                          <div className="dlab-post-title">
//                                             <h5 className="post-title m-t0 ">
//                                             {pageData.coreFunctions.purpose.title}
//                                             </h5>
//                                         </div>
//                                         <div className="dlab-post-text">
//                                         <ol>
//                                             {
//                                             pageData.coreFunctions.purpose.details.map((item, index) =><li typeof='number' key={index}>{item}</li>
//                                             )
//                                         }
//                                         </ol>
//                                         </div>
//                                          <div className="dlab-divider bg-gray-dark"></div>
//                                         <div className="dlab-post-title">
//                                             <h5 className="post-title m-t0 ">
//                                             {pageData.coreFunctions.duties.title}
//                                             </h5>
//                                         </div>
//                                         <div className="dlab-post-text">
//                                             <p>
//                                             {pageData.coreFunctions.duties.description}
//                                             </p>
//                                         </div>
//                                         <div className="dlab-post-text">
//                                          <ol>
//                                              {
//                                                 pageData.coreFunctions.duties.details.map((item, index) =><li typeof='number' key={index}>{item}</li>
//                                                 )
//                                             }
//                                          </ol>
//                                          </div>
//                                           <div className="dlab-divider bg-gray-dark"></div>
//                                         <div className="dlab-post-title">
//                                             <h5 className="post-title m-t0 ">
//                                               MINIMUM QUALIFICATIONS AND WORK EXPERIENCE
//                                             </h5>
//                                         </div>
//                                         <div className="dlab-post-text">
//                                             <p>
// 												{ pageData.coreFunctions.qualifications}
// 											</p>
//                                         </div>
//                                          <div className="dlab-divider bg-gray-dark"></div>
//                                          <div className="dlab-post-title">
//                                             <h5 className="post-title m-t0 ">
//                                               { pageData.thePerson.title}
//                                             </h5>
//                                         </div>
//                                         <div className="dlab-post-text">
//                                         <h5>
//                                              { pageData.thePerson.requirements.title}
//                                         </h5>
//                                         <ol>
//                                             {
//                                                 pageData.thePerson.requirements.details.map((item, index) =><li typeof='number' key={index}>{item}</li>
//                                                 )
//                                             }
//                                         </ol>
//                                         </div>