import React, { Component } from 'react'
// import { Link } from 'react-router-dom';
import Header from './../Layout/HeaderMenu'
import Footer from './../Layout/Footer'

import bgimg from './../../images/background/bg2.jpg'

class Contact extends Component {
  render() {
    return (
      <>
        <Header />
        <div className='full-section'>
          {/* <!-- inner page banner --> */}
          <div
            className='dlab-bnr-inr overlay-primary-dark contact-page'
            style={{ backgroundImage: 'url(' + bgimg + ')' }}
          >
            <div className='container'>
              <div className='row'>
                <div className='col-lg-4 col-xs-4'>
                  <div className='row text-white'>
                    <div className='col-lg-12 col-md-6 m-b30'>
                      <div className='icon-bx-wraper bx-style-1 p-a20 radius-sm'>
                        <div className='icon-content'>
                          <h5 className='dlab-tilte'>
                            <span className='icon-sm text-primary text-left'>
                              <i className='ti-location-pin'></i>
                            </span>
                            Company Address
                          </h5>
                          <p>
                            10 Barakin Ladi Road, Bukuru, Plateau State, Nigeria{' '}
                          </p>
                          <h6 className='m-b15 font-weight-400'>
                            <i className='ti-alarm-clock'></i> Office Hours
                          </h6>
                          <p className='m-b0'>Mon To Fri - 08.00-05.00</p>
                          <p>Sunday - Close</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-6 m-b30 '>
                      <div className='icon-bx-wraper bx-style-1 p-a20 radius-sm'>
                        <div className='icon-content'>
                          <h5 className='dlab-tilte'>
                            <span className='icon-sm text-primary text-left'>
                              <i className='ti-email'></i>
                            </span>
                            E-mail
                          </h5>
                          <p>info@nesconigeria.com, support@Nesconigeria.com</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-6 m-b30 '>
                      <div className='icon-bx-wraper bx-style-1 p-a20 radius-sm'>
                        <div className='icon-content'>
                          <h5 className='dlab-tilte'>
                            <span className='icon-sm text-primary text-left'>
                              <i className='ti-mobile'></i>
                            </span>
                            Phone Numbers
                          </h5>
                          <span>Bukuru fault reporting</span>
                          <p>08124176636, 07039232009</p>
                          <span>Barkin ladi</span>
                          <p>08088420560, 08065823120</p>
                          <span>Accounts section</span>
                          <p>07033688347</p>
                          <span>Control Foreman</span>
                          <p>08036345323</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-8 col-xs-8'>
                  <form
                    className='inquiry-form dzForm'
                    action='script/contact.php'
                  >
                    <div className='dzFormMsg'></div>
                    <h3 className='box-title m-t0 m-b10'>
                      Contact Us
                      <span className='bg-primary'></span>
                    </h3>

                    <div className='row'>
                      <div className='col-lg-6'>
                        <div className='form-group'>
                          <div className='input-group'>
                            <span className='input-group-addon'>
                              <i className='ti-user text-primary'></i>
                            </span>
                            <input
                              name='dzName'
                              type='text'
                              required
                              className='form-control'
                              placeholder='First Name'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='form-group'>
                          <div className='input-group'>
                            <span className='input-group-addon'>
                              <i className='ti-mobile text-primary'></i>
                            </span>
                            <input
                              name='dzOther[Phone]'
                              type='text'
                              required
                              className='form-control'
                              placeholder='Phone'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-12'>
                        <div className='form-group'>
                          <div className='input-group'>
                            <span className='input-group-addon'>
                              <i className='ti-email text-primary'></i>
                            </span>
                            <input
                              name='dzEmail'
                              type='email'
                              className='form-control'
                              required
                              placeholder='Your Email Id'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-12'>
                        <div className='form-group'>
                          <div className='input-group'>
                            <span className='input-group-addon'>
                              <i className='ti-agenda text-primary'></i>
                            </span>
                            <textarea
                              name='dzMessage'
                              rows='4'
                              className='form-control'
                              required
                              placeholder='Write a message'
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-12'>
                        <div className='form-group'>
                          <div className='input-group'>
                            <div
                              className='g-recaptcha'
                              data-sitekey='6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN'
                              data-callback='verifyRecaptchaCallback'
                              data-expired-callback='expiredRecaptchaCallback'
                            ></div>
                            <input
                              className='form-control d-none'
                              data-recaptcha='true'
                              required
                              data-error='Please complete the Captcha'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-12'>
                        <button
                          name='submit'
                          type='submit'
                          value='Submit'
                          className='site-button button-lg'
                        >
                          {' '}
                          <span>Send Message</span>{' '}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- inner page banner END --> */}
        </div>

        <Footer />
      </>
    )
  }
}
export default Contact
