import React, { Fragment, Component } from 'react'
import Counter from './Counter'
import bg1 from './../../images/background/bg1.jpg'

class SectionCounter extends Component {
  render() {
    return (
      <Fragment>
        <div
          className='section-full content-inner-1 overlay-primary about-service bg-img-fix'
          style={{ backgroundImage: 'url(' + bg1 + ')' }}
        >
          <div className='container'>
            <div className='section-head text-white text-center'>
              <h2 className='box-title m-tb0 max-w800 m-auto'>
                Amazing things happen to your businesses and homes when we
                connect those dots of utility and value
                <span className='bg-primary'></span>
              </h2>
            </div>
          </div>

          <div className='choses-info text-white'>
            <Counter />
          </div>
        </div>
      </Fragment>
    )
  }
}
export default SectionCounter
