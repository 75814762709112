import React from 'react'

const Privacy = () => {
  return (
    <div className='p-a50'>
        <h3 className='text-center'>MOBILE APPLICATION TERMS AND CONDITIONS</h3>
        <p>These terms and conditions apply to You, the the user of this application, and NESCO Nigeria Limited, the Owner and operator of the following application: NESCO (the <span className='font-weight-700'>"Application"</span>).</p>
        <p><span className='font-weight-700'>PLEASE READ THE TERMS AND CONDITIONS CAREFULLY</span> as they affect Your legal right.</p>

        <p className='m-t50 m-b50'>
            <ol type='1'>
                <li>
                    <p className='font-weight-700'>Definitions</p>
                    <p>"<span className='font-weight-700'>Parties</span>" means both You (the user of the Service) and the Owner of this Service.</p>
                    <p>"<span className='font-weight-700'>Content</span>" means any content, writing, images, audiovisual content or other information published on this Service.</p>
                    <p>"<span className='font-weight-700'>Your Content</span>" means any audio, video, text, images or other material You choose to display on this Application subject to the restrictions provided in this Agreement.</p>
                    <p>"<span className='font-weight-700'>Materials</span>" means any materials, information, or documentation that we may provide to You in connection with Your use of the Products including documentation, data, information developed any use and other materials which may assist in Your use of the Service.</p>
                    <p>"<span className='font-weight-700'>Terms</span>" means these terms and conditions.</p>
                    <p>"<span className='font-weight-700'>Service</span>" means the application, which is known as: NESCO including all pages, sub pages, all blogs, forums and other connected internet content whatsoever.</p>
                    <p>"<span className='font-weight-700'>Third Party Goods/Services</span>" means the goods and products both tangible and intangible offered on the Application.</p>
                    <p>"<span className='font-weight-700'>Products</span>" means the goods and products both tangible and intangible offered on the Application.</p>
                    <p>"<span className='font-weight-700'>Services</span>"  means the services offered on the Application.</p>
                </li>

                <li>
                    <p className='font-weight-700'>About this Application</p>
                    <p>The Application is an online post-paid and pre-paid (vending) system that provides a platform for customers, and staff of NESCO to manage their bills (which includes bill payments online) and provide NESCO staff to better manage field data capture, bill generation for customer respectively. This Service is offered to You upon Your acceptance of the Terms, conditions, notices hereinafter contained. Your use of this Service constitutes Your agreement to all the Terms contained herein.</p>
                </li>

                <li>
                    <p className='font-weight-700'>Agreement</p>
                    <p>a. By using this Application, You acknowledge that You have reviewed, considered the Terms of this Agreement and understand same and agree to be bound by it. If You do not agree with these Terms or do not intend to be bound by it, You must quit the use of this Application immediately. In addition, when using these Services, You shall be subject to any posted guidelines or rules applicable to such services. Accordingly, any participation in this Service shall constitute acceptance of this Agreement.</p>
                    <p>b. By using this Application and agreeing to these Terms, You represent and warrant that You have attained the age of 16 years.</p>
                </li>

                <li>
                    <p className='font-weight-700'>Acceptable Use</p>
                    <p>a. We may provide You with other items in connection with Your use of this Service.</p>
                    <p>b. We hereby grant You the license to use our Service for Your personal, non-commercial use to retrieve, display and view the Content on a computer and phone screens.</p>
                    <p>c. The license created under these Terms is limited, non-exclusive, non-transferable and revocable.</p>
                    <p>d. You agree that You will not use the Contents or Materials for any other purpose which may be contrary to your license to use this Service.</p>
                    <p>e. Any unauthorized use by You shall terminate the permission or license granted by this Application.</p>
                </li>

                <li>
                    <p className='font-weight-700'>Prohibited Use</p>
                    <p>a. You are expressly prohibited from collecting, downloading, copying or otherwise communicating with other Users from the Application.</p>
                    <p>
                    b. You agree not to use the Service in the following manner:
                    <br/>(I). to harass, abuse or threaten others or otherwise violate any person's legal rights;
                    <br/>(II). to perpetrate fraud;
                    <br/>(III). to create or transmit unnecessary spam to any person or URL;
                    <br/>(IV). to post, transmit or cause to be posted or transmitted, any communication or solicitation designed to obtain password, account, or private information of other Users or persons;
                    <br/>(V). to post copyrighted content which does not belong to You and without obtaining the prior consent of the author;
                    <br/>(VI). to use robot, spider, scraper or other automated means to access this Service without obtaining the prior consent of the Owner;
                    <br/>(VII). to engage in or create any unlawful gambling, sweepstakes, or scheme;
                    <br/>(VIII). publishing or distributing any obscene or defamatory material;
                    <br/>(IX). using this Service in any way that impacts user access to the Application;
                    <br/>(X). to engage in advertisement or solicit any User to buy or sell products or services without obtaining the prior consent of the Owner;
                    <br/>(XI). disseminating computer viruses or other software;
                    <br/>(XII). violating any intellectual property rights of the Owner or any third party;
                    <br/>(XIII). to use the Application or any of the Services for illegal spam activities.
                    </p>
                    <p>
                    c. Additionally, you agree that You will not do as follows:
                    <br/>(I). interfere or attempt to interfere with the proper working of this Application; or
                    <br/>(II). bypass any measures we may use to prevent or restrict access to this Application;
                    <br/>(III). to interfere with or circumvent the security features of this Service;
                    <br/>(IV). to damage, disable, overburden or impair this Service or any other person's use of this Service.
                    <br/>(V). to use this Service contrary to the applicable laws and regulations or in a way that causes, or may cause harm to this Application, any person or business entity.
                    </p>
                    <p>d. The Owner has the authority to review all content posted by the Users on this Application and we reserve the right to terminate Your use of the Service for violating any of the prohibited uses.</p>
                </li>

                <li>
                    <p className='font-weight-700'>Intellectual Property Ownership</p>
                    <p> a. You agree that we retain ownership of all Content included on the Application (text, graphics, video, software, data, page layout, images, and any other information capable of being stored in a computer) other than the contents uploaded by users.</p>
                    <p>b. You are granted a limited license only, subject to the restrictions provided in this Terms, nothing on this Application shall be construed as granting any license or right to use any trademark or logo displayed on the Application without obtaining the prior written consent of the Owner.</p>
                    <p>c. You hereby agree not to reproduce or distribute the Owner's intellectual property or use the intellectual property for any unlawful purpose.</p>
                </li>

                <li>
                    <p className='font-weight-700'>Your Content</p>
                    <p>a. You undertake that You retain the exclusive right and ownership of Your Content and You are not infringing on any third party rights.</p>
                    <p>b. You retain all rights and ownership to Your Content. However, when You upload Your Content, You grant the Owner a worldwide license to communicate, distribute, host, make modification or derivative works (solely for the purpose of showcasing Your work), reproduce, publicly display, publicly perform and use such content. The license granted by You is for the purposes of marketing, promoting, and improving our services.</p>
                    <p>c. The Owner reserves the right to remove any of Your Content or any content that is unlawful, offensive, defamatory, or otherwise objectionable or violates any intellectual property rights or thees Terms.</p>
                </li>

                <li>
                    <p className='font-weight-700'>User Account</p>
                    <p>a. You may be required to register with us to have access to Products and Service sold or offered by registered shop owners.</p>
                    <p>b. You will be required to provide certain personal information, which includes but not limited to Your name, user name, email address and password. The information provided must be correct and accurate.</p>
                    <p>c. This personal information must not be disseminated to anyone and when You discover that Your information has been compromised, You agree to notify us immediately. You also acknowledge that You are responsible for the security of Your personal information and that the Owner does not accept liability for the security of Your account as You agree to be responsible for maintaining the confidentiality of Your passwords or other account identifiers which You choose and all activities under Your account.</p>
                    <p>d. The Owner reserves the right to terminate Your account where You have provided false inaccurate or incorrect information.</p>
                    <p>e. It is at the sole discretion of the Owner to terminate the account.</p>
                </li>

                <li>
                    <p className='font-weight-700'>Services</p>
                    <p>a. The Owner undertakes to give accurate information about the description of the Products and Services. However,</p>
                    <p>b. We reserve the right to refuse or disable a user application or registration on the app.</p>
                    <p>d. If You are not satisfied with your purchase? contact the company via the official emails or phone numbers on the website.</p>
                </li>

                 <li>
                    <p className='font-weight-700'>Payment and Billing</p>
                    <p>a. The total price will also include the taxes applicable on the date of purchase.</p>
                    <p>b. The total price of the Products and Services including all applicable taxes and charges  are included upon the confirmation of purchase or payments.</p>
                </li>

                 <li>
                    <p className='font-weight-700'>Token Delivery</p>
                    <p>a. For pre-paid meter users a token is generated and sent to user after a successful payment is made via SMS, email or via the app</p>
                    <p>b. post-paid meter customers will have the total amount paid by the customer subtracted from the bill they owe.</p>
                </li>

                <li>
                    <p className='font-weight-700'>Privacy Policy</p>
                    <p>To use our Service, we require that You provide certain personal information, By using our Service, You hereby grant the Owner the authority the Owner to use Your personal information.</p>
                    <p>a. Information we collect and use:</p>
                    <p>
                        <span className='font-weight-700'>For registered users:</span> You provide personal information, such as Your name, user name, email address, or billing information if You register for an account with the Service emails from Us; commenting on our Content or other User Content on our Application; and the purchases You make.
                    </p>
                    <p>
                        We may also require other information in relation to but not limited to the following: receiving notifications by text message or email about marketing; receiving general
                    </p>

                     <p>
                       <span className='font-weight-700'> For unregistered users:</span>
                       We will collect passive information from all registered and unregistered users. These information include cookies, IP address information, location information and certain browser information.
                    </p>

                     <p>
                       <span className='font-weight-700'>Sales and billing information:</span>
                        We will not collect Your credit and/or debit card information, which includes Your card number, password, etc, Your billing address, Your contact address and other information required for Your purchases, however, these are collected by the payment gateway service used on the app.
                    </p>

                     <p>
                       <span className='font-weight-700'>User experience:</span>
                        From time to time we may also request certain Personal Data that may be necessary to improve our Service and the Products and Services we offer for sale on the Application.
                    </p>

                    <p>
                        We may also receive information from external applications You use to access our Service or information through various web technologies, such as cookies, log files, clear gifts with Your permission.
                    </p>

                    <p>
                        b. How we use the information: We use Your information to provide personalized service to You. We also use it to help monitor and improve the Service we offer. We may also track certain information received to improve our marketing. We will only use Your personal data for the purpose it was intend and with Your permission.
                    </p>

                    <p>
                        c. How to protect Your information: We will use administrative security measures to reduce the risks of loss or misuse. While the security of Your information is paramount to us, we cannot guarantee its absolute security. If You choose to terminate Your account, You agree that the Owner will save Your information for a reasonable period with reference to the purpose for obtaining the personal data.
                    </p>
                </li>

                 <li>
                    <p className='font-weight-700'>Electronic Communications</p>
                    <p>
                       You consent to receive electronic communications and You agree that all agreements, notices, disclosures and other communications we provide to You electronically, via email and on this Application, satisfy any legal requirements that communications must be in writing.
                    </p>
                </li>

                 <li>
                    <p className='font-weight-700'>Reverse Engineering and Security</p>
                    <p>
                        You hereby agree as follows:
                        <br/>(I). not to reverse engineer or permit the reverse engineering or dissemble any code or software from or on the Application or Services; and
                        <br/>(II). not to violate the Security of the Application or other Services through any unauthorized access, circumvention of encryption or other security tools, data mining or interference with any host or User or network.
                    </p>
                </li>

                 <li>
                    <p className='font-weight-700'>Change to Service</p>
                    <p>
                       a. You accept that the Owner may vary, alter, amend, or update the Content or Service, Products and Services at any time and without Your consent.
                    </p>
                     <p>
                        b. You also agree that the Products and Services may not be available at all times and this may be as a result of the maintenance or for any other reason and we shall not be held liable for the failure to provide this Service.
                    </p>
                </li>

                 <li>
                    <p className='font-weight-700'>Indemnification</p>
                    <p>You hereby agree to indemnify the Owner, its employees, agents; and third parties from and against all liabilities, cost, demands, cause of action, damages; and expenses (including reasonable attorney's fees) arising out of Your use or inability to use, any uploads made by You, Your violation of any rights of a third party and Your violation of applicable laws, rules or regulation.</p>
                </li>

                 <li>
                    <p className='font-weight-700'>Service Interruptions</p>
                    <p>The Owner may from time to time interrupt Your access or use of this Application to perform some maintenance or emergency services and You agree that the Owner shall not be held liable for any damage, loss which may arise thereof.</p>
                </li>

                <li>
                    <p className='font-weight-700'>Termination/Restriction of Access</p>
                    <p>The Owner reserves the right to, at its sole discretion, terminate Your access to this Application and the related Service or any part thereof at any time, for any reason and without notice.</p>
                    <p>
                        The Owner shall have the right to terminate or terminate/suspend Your account for violating the Terms of this Service.
                    </p>
                    <p>
                        If You register with us, You may terminate this Service at anytime by issuing a prior notice to us. Once this is done, You will no longer be bound by the provisions of this Terms.
                    </p>
                </li>

                 <li>
                    <p className='font-weight-700'>Termination/Restriction of Access</p>
                    <p>The Owner reserves the right to, at its sole discretion, terminate Your access to this Application and the related Service or any part thereof at any time, for any reason and without notice.</p>
                </li>

                 <li>
                    <p className='font-weight-700'>General Provisions</p>
                    <p>a. <span className='font-weight-700'>Assignment:</span> The Owner shall be permitted to assign, transfer its rights and/or obligations under these Terms. However, You shall not be permitted to assign, transfer any rights and/or obligations under these Terms.</p>
                    <p>b. <span className='font-weight-700'>Entire Agreement:</span> These Terms, disclaimers and any other agreement relating to the use of this Application constitutes the entire agreement and shall supersede any other agreement.</p>
                    <p>c. <span className='font-weight-700'>Separate Agreements:</span> You may have other legal agreements with us. Those agreements are separate from these Terms. These Terms are not intended to alter, amend, revise or replace the terms of the other agreement.</p>
                    <p>d. <span className='font-weight-700'>Applicable law:</span> These Terms may be governed and construed in accordance with the Laws, regulations or guidelines of the Federal Republic of Nigeria and other treaties, or regulations which is applicable in Nigeria.</p>
                    <p>e. <span className='font-weight-700'>Variation:</span> The Owner may revise these Terms at any time as it sees fit, and by using this Application, You undertake that You shall review the terms of the revised Terms before accepting same. If any part of the of the Terms or any modification thereof is considered invalid or unenforceable, the remaining parts shall be considered valid and enforceable.</p>
                    <p>g. <span className='font-weight-700'>Waiver:</span> Failure to exercise any right in these Terms shall not operate as a waiver. The right or remedies herein provided are cumulative and not exclusive of any right or remedies provided by law.</p>
                    <p>a. <span className='font-weight-700'>Severability:</span> Every provision contained herein is intended to be severable. If any provision is invalid for any reason whatsoever, such invalidity shall not affect the validity of other clauses of these Terms.</p>
                </li>
            </ol>
        </p>
    </div>
  )
}

export default Privacy