import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

import moment from 'moment/moment';

function SampleNextArrow(props) {
	const { onClick } = props;
	return (
		<div className="owl-nav">
			<div className="owl-next flaticon-right-arrow" onClick={onClick} />
		</div>
	);
}

function SamplePrevArrow(props) {
	const { onClick } = props;
	return (
		<div className="owl-nav">
			<div className=" owl-prev flaticon-left-arrow" onClick={onClick} style={{ zIndex: 1 }} />
		</div>
	);
}

const LatestBlogSlider = ({ news }) => {

	var settings = {
		arrows: true,
		slidesToShow: 3,
		infinite: false,
		dots: false,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	};
	return (
		<>
			<Slider className="dots-style-center img-carousel owl-carousel owl-btn-center-lr owl-btn-3 " {...settings}>
				{news?.map((item, index) => (
					<div className="item p-3" key={index}>
						<div className="blog-post blog-grid blog-rounded blog-effect1">
							<div className="dlab-post-media dlab-img-effect "> <Link
								to={`/news-details/${item.id}`}><img src={item.cover_image} alt="" /></Link> </div>
							<div className="dlab-info p-a20 border-1">
								<div className="dlab-post-title ">
									<h5 className="post-title font-weight-500"><Link to={`/news-details/${item.id}`}>{item.title}</Link></h5>
								</div>
								<div className="dlab-post-meta ">
									<ul>
										<li className="post-date"> <i className="fa fa-comments"></i><strong>{moment(item.updatedAt).format('LL')}</strong></li>
									</ul>
								</div>
								<div className="dlab-post-readmore">
									<Link to={`/news-details/${item.id}`} title="READ MORE" rel="bookmark" className="site-button-link black outline">READ MORE
										<i className="ti-arrow-right"></i>
									</Link>
								</div>
							</div>
						</div>
					</div>
				))}
			</Slider>
		</>
	)
}

export default LatestBlogSlider;