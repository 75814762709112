import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const HeaderMenuItems = () => {
  const { pathname } = useLocation()

  return (
    <>
      <ul className='nav navbar-nav'>
        <li className={pathname === '/' ? 'active' : ''}>
          <Link to={'/'}>Home</Link>
        </li>
        <li className={pathname === '/about' ? 'active' : ''}>
          <Link to={'/about'} className='dez-page'>
            About Us
          </Link>
        </li>
        <li className={pathname === '/services' ? 'active' : ''}>
          <Link to={'/services'} className='dez-page'>
            Services
          </Link>
        </li>
        <li className={pathname === '/portfolio' ? 'active' : ''}>
          <Link to={'/portfolio'} className='dez-page'>
            Portfolio
          </Link>
        </li>
        <li className={pathname === '/news' ? 'active' : ''}>
          <Link to={'/news'} className='dez-page'>
            News
          </Link>
        </li>
        <li className={pathname === '/contact' ? 'active' : ''}>
          <Link to={'/contact'} className='dez-page'>
            Contact Us
          </Link>
        </li>
      </ul>
    </>
  )
}
export default HeaderMenuItems
