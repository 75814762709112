import React, { Component } from 'react'
// import { Link } from 'react-router-dom';
import Header from './../../Layout/HeaderMenu'
import Footer from './../../Layout/Footer'
import PageTitle from './../../Layout/PageTitle'

//Images
import about9 from './../../../images/about/pic9.jpg'
import bg2 from './../../../images/background/bg-map.jpg'
import services1 from './../../../images/our-services/pic1.jpg'
import services2 from './../../../images/our-services/pic2.jpg'

import bnr from './../../../images/banner/bnr2.jpg'

class Aboutus1 extends Component {
  render() {
    return (
      <>
        <Header />
        <div className='page-content bg-white'>
          <div
            className='dlab-bnr-inr overlay-primary'
            style={{ backgroundImage: 'url(' + bnr + ')' }}
          >
            <PageTitle motherMenu='About Us' activeMenu='About Us' />
          </div>
          <div className='content-block'>
            <div className='section-full content-inner-2'>
              <div className='container'>
                <div className='section-head text-black text-center'>
                  <h4 className='text-gray-dark m-b10'>About Us</h4>
                  <h2 className='box-title m-tb0'>
                    We are all about customer satisfaction
                    <span className='bg-primary'></span>
                  </h2>
                  <p>
                    An established business, with experienced management and
                    engineering teams, long term customer relationships and
                    reputable market presence.
                  </p>
                </div>
              </div>
              <div className='container'>
                <div className='row '>
                  <div className='col-lg-12 col-md-12'>
                    <div className='abuot-box left row m-lr0 '>
                      <div className='col-lg-4'>
                        <h2 className='box-title m-tb0'>
                          About Us<span className='bg-primary'></span>
                        </h2>
                        <h4 className='text-gray-dark'>
                          We create unique experiences
                        </h4>
                        <div className='about-img'>
                          <img src={about9} data-tilt alt='' />
                        </div>
                      </div>
                      <div className='col-lg-8'>
                        <p>
                          The Nigerian Electricity Supply Corporation (Nigeria)
                          Limited commenced operations in Nigeria in 1929 and
                          has developed and operates generation, transmission
                          and distribution systems to supply areas within the
                          Plateau State and adjacent states. It is an
                          investor-owned electric utility company with a
                          generation capacity of 175GWhrs per annum from both
                          hydro-electric and thermal sources of power. It serves
                          an area in excess of 14,000 square kilometres across
                          the Plateau State, Benue State, Nasarawa State, Kaduna
                          State, the Federal Capital Territory and adjacent
                          states and supplies mining, industrial consumers and
                          domestic consumers in these areas. It also supplies bulk electricity to interested entities and state government’s rural electricity distribution networks not connected to the national
                          grid. The Company has built seven hydroelectric power
                          stations on various river systems in Plateau state and
                          operates these stations with a combined annual
                          hydro-electric generation capability of 145GWhrs. of
                          electricity, which includes construction and
                          maintenance and operation of large reservoirs with
                          sufficient water storage capability for all season
                          operation of its plants.
                        </p>
                        {/* <p>
                          Technical management for the Company is provided by
                          NESCO Investments Limited, a company registered in
                          England and Wales which is a shareholder of the
                          Company. The Company's staff, backed up by its UK
                          associate technical consultant company, NESCO Projects
                          Limited (a wholly owned subsidiary of NESCO
                          Investments Limited), are well experienced in the
                          design, installation, maintenance and operation of
                          hydro- electric power stations and equipment and the
                          distribution and sale of electricity.
                        </p> */}
                        <p className='m-b0'>
                          The Company's construction division has built many electrification projects for various state governments, now defunct PHCN and private commercial companies and has also when required, managed some of these systems for its clients on a commercially viable basis. The Company is committed to providing its consumers with steady and continuous electricity supply and has been
                          doing this very successfully for its customers for
                          over 90 years. It continues to supply its rural areas
                          and industrial consumers with a 365/24/7 electricity
                          supply and is actively pursuing new opportunities as
                          they arise.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Counetr section End*/}
            <VisionBlog />
          </div>
        </div>
        <Footer />
      </>
    )
  }
}
function VisionBlog() {
  return (
    <>
      <div
        className='section-ful our-about-info content-inner-1 '
        style={{
          backgroundImage: 'url(' + bg2 + ')',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className='container'>
          <div className='section-head text-center'>
            <h2 className='box-title m-tb0'>
             Mission, Vision and Core Values<span className='bg-primary'></span>
            </h2>
          </div>
          <div className=' row dzseth  m-b30'>
            <div className='col-lg-6 col-md-12 m-b30 about-img '>
              <img src={services1} data-tilt alt='' />
            </div>
            <div className='col-lg-6 col-md-12 m-b30 dis-tbl text-justify'>
              <div className='dis-tbl-cell'>
           
                <h3 className='box-title mb-0'>
                  Motto<span className='bg-primary'></span>
                </h3>
                <p className='font-16'>
                Reliable Power you can Trust.
                </p>
                <h3 className='box-title mb-0'>
                  Mision<span className='bg-primary'></span>
                </h3>
                <p className='font-16'>
                 To provide reliable and clean electrical power that surpasses Customer Expectation.
                </p>

                <h3 className='box-title mb-0'>
                  Vision<span className='bg-primary'></span>
                </h3>
                <p className='font-16'>
                  To be recognized as a Cutting-Edge Power Solution Provider profitable to all Stakeholders.
                </p>
              </div>
            </div>
          </div>
          <div className='row dzseth column-reverse'>
            <div className='col-lg-6 col-md-12 dis-tbl text-justify'>
              <div className='dis-tbl-cell'>
                <h3 className='box-title'>
                  Core Values<span className='bg-primary'></span>
                </h3>
                <p className='font-16'>
                      <ul className='list-check primary'>
                        <li>Team Work</li>
                        <li>Integrity, Fairness and Loyalty</li>
                        <li>Market Leader</li>
                        <li>Environmental and Safety Consciousness</li>
                        <li>Diversity and Inclusion</li>
                        <li>Customer Centric</li>
                        <li>Corporate Social Responsibility</li>
                        <li>Initiative and Drive for Productivity</li>
                      </ul>
                </p>
               
              </div>
            </div>
            <div className='col-lg-6 col-md-12 about-img '>
              <img src={services2} data-tilt alt='' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { VisionBlog }
export default Aboutus1
