import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './../../Layout/HeaderMenu'
import Footer from './../../Layout/Footer'
import PageTitle from './../../Layout/PageTitle'
import SectionCounter from './../../Element/SectionCounter'

//Images
import bnr5 from './../../../images/banner/bnr5.jpg'

const iconBox = [
  {
    icon: <i className='flaticon-devices' />,
    title: 'Vending',
    message: '',
  },
  {
    icon: <i className='flaticon-pen' />,
    title: 'Customer Services',
  },

  {
    icon: <i className='flaticon-file' />,
    title: 'Maintainance',
    message: '',
  },
  {
    icon: <i className='flaticon-notebook' />,
    title: 'Electricity Generation',
    message: '',
  },
  {
    icon: <i className='flaticon-bar-chart' />,
    title: 'ONLINE POST-PAID BILLING PAYMENT',
    message: '',
  },
  {
    icon: <i className='flaticon-team' />,
    title: 'Electricity Distribution',
    message: '',
  },
]

class Service extends Component {
  render() {
    return (
      <>
        <Header />

        <div className='page-content bg-white'>
          {/* <!-- inner page banner --> */}
          <div
            className='dlab-bnr-inr overlay-primary'
            style={{ backgroundImage: 'url(' + bnr5 + ')' }}
          >
            <PageTitle motherMenu='Our Services' activeMenu='Our Services' />
          </div>
          {/* <!-- inner page banner END --> */}
          <div className='content-block'>
            {/* <!-- About Us --> */}
            <div className='section-full content-inner'>
              <div className='container'>
                <div className='section-head text-black text-center'>
                  <h4 className='text-gray-dark m-b10'>Our Services</h4>
                  <h2 className='box-title m-tb0'>
                    Electricity generation, transmission and distribution
                    <span className='bg-primary'></span>
                  </h2>
                  <p>
                   NESCO has established a reputation for reliability and the ability to provide electric power 365/24/7 with  minimum outages.
                  </p>
                </div>
              </div>
              <div className='container'>
                <div className='row '>
                  {iconBox.map((data, index) => (
                    <div className='col-md-4 col-sm-6 m-b30 ' key={index}>
                      <div className='icon-bx-wraper expertise  bx-style-1 p-a30 center'>
                        <div className='icon-lg m-b20'>
                          {' '}
                          <Link to={'#'} className='icon-cell'>
                            {data.icon}
                          </Link>{' '}
                        </div>
                        <div className='icon-content'>
                          <h5 className='dlab-tilte text-uppercase'>
                            <Link to={'#'}>{data.title}</Link>
                          </h5>
                          <p>{data.message}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* <!-- Our Services --> */}
            {/* <!-- Why Chose Us --> */}
            <SectionCounter />
          </div>
          {/* <!-- contact area END --> */}
        </div>

        <Footer />
      </>
    )
  }
}
export default Service
