import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import HeaderMenuItems from './HeaderMenuItems'

class HeaderMenu extends Component {
  componentDidMount() {
    // sidebar open/close

    var Navicon = document.querySelector('.navicon')
    var sidebarmenu = document.querySelector('.myNavbar ')

    function toggleFunc() {
      sidebarmenu.classList.toggle('show')
      Navicon.classList.toggle('open')
    }
    Navicon.addEventListener('click', toggleFunc)

    // Sidenav li open close
    var navUl = [].slice.call(
      document.querySelectorAll('.navbar-nav > li > a, .sub-menu > li > a')
    )
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener('click', function () {
        checkLi(this)
      })
    }

    function checkLi(current) {
      current.parentElement.parentElement
        .querySelectorAll('li')
        .forEach((el) =>
          current.parentElement !== el ? el.classList.remove('open') : ''
        )

      setTimeout(() => {
        current.parentElement.classList.toggle('open')
      }, 100)
    }
  }

  openInNewTab(url) {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  render() {
    return (
      <>
        <header
          className='site-header header-transparent mo-left'
          id='fix-header'
        >
          <div className='top-bar'>
            <div className='container'>
              <div className='row d-flex justify-content-between'>
                <div className='dlab-topbar-left'>
                  <ul>
                    <li>
                      <i className='flaticon-phone-call m-r5'></i> 07039232009
                    </li>
                    <li>
                      <i className='ti-location-pin m-r5'></i> 10 Barakin Ladi
                      Road, Bukuru, Plateau State, Nigeria
                    </li>
                  </ul>
                </div>
                <div className='dlab-topbar-right'>
                  <ul>
                    <li>
                      <i className='ti-email m-r5'></i> info@nesconigeria.com
                    </li>
                    <li>
                    <li>

                    <button
                     className='site-button outline p-2 white'
                      onClick={() => this.openInNewTab('https://portal.nesconigeria.com/')}>
                      Login
                    </button>
                    </li>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className='sticky-header main-bar-wraper navbar-expand-lg'>
            <div className='main-bar clearfix '>
              <div className='container clearfix align-items-center'>
                <div className='logo-header mostion mt-1 mb-1'>
                  <Link to={'/'} className='dez-page'>
                   <img
                      className='app-logo'
                      src='/images/Nesco_LOGO.png'
                      alt='Nesco'
                      style={{width:'48%',}}
                    />
                  </Link>
                </div>

                <p style={{position: 'absolute', marginLeft:'4.5rem', color: 'white'}} className='mt-3 mb-0'>Reliable Power you can Trust.</p>

                <button
                  className='navbar-toggler collapsed navicon justify-content-end'
                  type='button'
                  data-toggle='collapse'
                  data-target='#navbarNavDropdown'
                  aria-controls='navbarNavDropdown'
                  aria-expanded='false'
                  aria-label='Toggle navigation'
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>

                <div
                  className='header-nav navbar-collapse collapse myNavbar justify-content-end'
                  id='navbarNavDropdown'
                >
                  <div className='logo-header mostion d-md-block d-lg-none'>
                    <Link to={'./'} className='dez-page'>
                     <img
                      className='app-logo'
                      src='/images/Nesco_LOGO.png'
                      alt='Nesco'
                      style={{width:'25%',}}
                    />
                    </Link>
                    <p style={{color: 'black'}} className='mt-3 mb-0'>Reliable Power you can Trust.</p>
                  </div>
                  {/*  Header Menu Contents  */}
                  <HeaderMenuItems />
                  {/*  Header Menu Contents End */}
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    )
  }
}

export default HeaderMenu
