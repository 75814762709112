import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Footer4 extends Component {
  render() {
    return (
      <>
        <footer className='site-footer text-uppercase footer-white'>
          <div className='footer-top'>
            <div className='container'>
              <div className='row'>
                <div className='col-5 col-lg-2 col-md-6 col-sm-6 footer-col-4'>
                  <div className='widget widget_services border-0'>
                    <h5 className='m-b30 text-white'>Company</h5>
                    <ul>
                      <li>
                        <Link to={'/'}>Home </Link>
                      </li>
                      <li>
                        <Link to={'/about'}>About Us </Link>
                      </li>
                      <li>
                        <Link to={'/services'}>Our Services</Link>
                      </li>
                      <li>
                        <Link to={'/portfolio'}>Portfolio</Link>
                      </li>
                      <li>
                        <Link to={'/contact'}>Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-7 col-lg-2 col-md-6 col-sm-6 footer-col-4'>
                  <div className='widget widget_services border-0'>
                    <h5 className='m-b30 text-white'>Useful Link</h5>
                    <ul>
                      <li>
                        <Link to={'#'}>Pay Bills</Link>
                      </li>
                      <li>
                        <Link to={'/news'}>News</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6 footer-col-4'>
                  <div className='widget widget_getintuch'>
                    <h5 className='m-b30 text-white '>Contact us</h5>
                    <ul>
                      <li>
                        <i className='ti-location-pin'></i>
                        <strong>address</strong>10 Barakin Ladi Road, Bukuru,
                        Plateau State, Nigeria PO Box 15, Bukuru
                      </li>
                      <li>
                        <i className='ti-mobile'></i>
                        <strong>phone</strong>
                        <span>Bukuru fault reporting:</span>
                        <p>08124176636, 07039232009</p>

                        <span>Barkin ladi:</span>
                        <p>08088420560, 08065823120</p>
                      </li>
                      <li className='text-lowercase'>
                        <i className='ti-email'></i>
                        <strong>email</strong>info@nesconigeria.com, support@Nesconigeria.com
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6 footer-col-4 '>
                  <div className='widget'>
                    <h5 className='m-b30 text-center text-white'>
                      Connect to us on social media
                    </h5>

                     {/* <a
                    href='https://www.linkedin.com/company/zeedworldwide'
                    target='_blank'
                  >
                    <i className='icofont-linkedin'></i>
                    <span>Linkedin</span>
                  </a> */}

                    <div className='d-flex justify-content-center'>
                      <ul className='list-inline m-a0'>
                        <li>
                          <a
                            href = 'https://web.facebook.com/profile.php?id=100077183903079'
                            className='site-button facebook circle mr-1'
                            target="_blank" rel="noreferrer" 
                          >
                            <i className='fa fa-facebook'></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href='https://www.linkedin.com/in/nesco-nigeria-286638234/'
                            className='site-button linkedin circle mr-1'
                            target="_blank" rel="noreferrer" 
                          >
                            <i className='fa fa-linkedin'></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href='https://www.instagram.com/nesconigeria/'
                            className='site-button instagram circle mr-1'
                            target="_blank" rel="noreferrer" 
                          >
                            <i className='fa fa-instagram'></i>
                          </a>
                        </li>
                        <li>
                          <a href='https://twitter.com/nesconigeria'
                            className='site-button twitter circle mr-1'
                            target="_blank" rel="noreferrer"
                          >
                            <i className='fa fa-twitter'></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='footer-bottom bg-primary'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-6 text-left '>
                  {' '}
                  <span>Copyright © 2021</span>{' '}
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 text-right '>
                  <div className='widget-link '>
                    <ul>
                      <li>
                        <Link to={'#'}> Help Desk</Link>
                      </li>
                      <li>
                        <Link to={'#'}> Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  }
}

export default Footer4
