import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

import Slider1 from './../../images/main-slider/slide1.jpg'
import Slider11 from './../../images/main-slider/slider11.jpg'
import Slider12 from './../../images/main-slider/slider12.JPG'

function SampleNextArrow(props) {
  const { onClick } = props
  return (
    <div className='owl-nav'>
      <div className='owl-next la la-angle-right' onClick={onClick} />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { onClick } = props
  return (
    <div className='owl-nav'>
      <div
        className=' owl-prev la la-angle-left'
        onClick={onClick}
        style={{ zIndex: 1 }}
      />
    </div>
  )
}

class HomeOwlSlider extends Component {
  render() {
    var settings = {
      arrows: true,
      dots: true,
      slidesToShow: 1,
      infinite: true,
      autoplay: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }
    return (
      <Slider
        className='owl-slider owl-carousel owl-theme owl-none'
        {...settings}
      >
        <div className='item slide-item'>
          <div className='slide-item-img'>
            <img src={Slider11} className='w-100' alt='' />
          </div>
          <div className='slide-content overlay-primary'>
            <div className='slide-content-box container'>
              <div className='max-w600 text-white'>
                <h2 className='text-white font-weight-400'>
                  Committed to providing steady
                  <br />
                  and continuous electricity supply.
                  <br />
                </h2>
                <p>
                  We move with an established reputation for reliability and the
                  ability to provide electric power with minimum outages.
                </p>

                {/* <Link to={'#'} className='site-button m-r10 white button-lg'>
                  Get Started
                </Link> */}
                <Link
                  to={'#'}
                  className='site-button outline outline-2 button-lg mb-4'
                >
                  Pay Bills
                </Link>

                <div className='d-flex'>
                  <Link to={'#'} className='mr-3'>
                    <img
                      className='app-download-button'
                      src='/images/apple-store.png'
                      alt='app-store'
                    />
                  </Link>
                  <Link to={'#'} className=''>
                    <img
                      className='app-download-button'
                      src='/images/google-play.png'
                      alt='app-store'
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='item slide-item'>
          <div className='slide-item-img'>
            <img src={Slider1} className='w-100' alt='' />
          </div>
          <div className='slide-content overlay-primary'>
            <div className='slide-content-box container'>
              <div className='max-w600 text-white'>
                <h2 className='text-white font-weight-400'>
                  Enjoy a strong in-house maintenance
                  <br /> and people–oriented culture.
                  <br />
                </h2>
                <p>
                  We are well experienced in the design, installation,
                  maintenance and operation of hydro- electric power stations
                  and equipment and the distribution and sale of electricity.
                </p>
                <Link
                  to={'#'}
                  className='site-button outline outline-2 button-lg mb-4'
                >
                  Pay Bills
                </Link>
                <div className='d-flex'>
                  <Link to={'#'} className='mr-3'>
                    <img
                      className='app-download-button'
                      src='/images/apple-store.png'
                      alt='app-store'
                    />
                  </Link>
                  <Link to={'#'} className=''>
                    <img
                      className='app-download-button'
                      src='/images/google-play.png'
                      alt='app-store'
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='item slide-item'>
          <div className='slide-item-img'>
            <img src={Slider12} className='w-100' alt='' />
          </div>
          <div className='slide-content overlay-primary'>
            <div className='slide-content-box container'>
              <div className='max-w600 text-white'>
                <h2 className='text-white font-weight-400'>
                  Inspiring solutions
                  <br />
                  focused on customer satisfaction.
                  <br />
                </h2>
                <p>
                  We continue to supply rural areas and industrial consumers
                  with steady electricity supply and are actively pursuing new
                  opportunities as they arise.
                </p>
                <Link
                  to={'#'}
                  className='site-button outline outline-2 button-lg mb-4'
                >
                  Pay Bills
                </Link>
                <div className='d-flex'>
                  <Link to={'#'} className='mr-3'>
                    <img
                      className='app-download-button'
                      src='/images/apple-store.png'
                      alt='app-store'
                    />
                  </Link>
                  <Link to={'#'} className=''>
                    <img
                      className='app-download-button'
                      src='/images/google-play.png'
                      alt='app-store'
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    )
  }
}

export default HomeOwlSlider
