import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import HomePage from './Pages/HomePage'
import Aboutus1 from './Pages/Aboutus/Aboutus1'
import Error403 from './Pages/Error/Error403'
import Error404 from './Pages/Error/Error404'
import Error405 from './Pages/Error/Error405'
// import ComingSoon1 from './Pages/ComingSoon/ComingSoon1'
// import UnderMaintenance from './Pages/UnderMaintenance'
// import ProjectDetails from './Pages/ProjectDetails'
import Faqs from './Pages/Faqs'
import Login from './Pages/Login'
// import Register from './Pages/Register'
import Contact from './Pages/Contact'
import Service from './Pages/Service/Service'
import ServicesDetails from './Pages/Service/ServicesDetails'
import News from './Pages/News'
import NewsDetails from './Pages/NewsDetails'
import ProjectGrid from './Pages/Project/ProjectGrid'
import TeamDetails from './Pages/TeamDetails'

import ScrollToTop from './Element/ScrollToTop'

import Privacy from './Pages/Privacy'

class Markup extends Component {
  render() {
    return (
      <BrowserRouter basename='/'>
        <div className='page-wraper'>
          <Switch>
            <Route path='/' exact component={HomePage} />
            <Route path='/about' exact component={Aboutus1} />
            <Route path= '/private-policy' exact component={Privacy} />
            <Route path='/error-403' exact component={Error403} />
            <Route path='/error-404' exact component={Error404} />
            <Route path='/error-405' exact component={Error405} />
            {/* <Route path='/coming-soon-1' exact component={ComingSoon1} /> */}
            {/* <Route
              path='/under-maintenance'
              exact
              component={UnderMaintenance}
            /> */}
            {/* <Route path='/project-details' exact component={ProjectDetails} /> */}
            <Route path='/faqs' exact component={Faqs} />
            <Route path='/login' exact component={Login} />
            {/* <Route path='/register' exact component={Register} /> */}
            <Route path='/contact' exact component={Contact} />
            <Route path='/services' exact component={Service} />
            <Route path='/services-details' exact component={ServicesDetails} />
            <Route path='/news' exact component={News} />
            <Route path='/news-details/:ID/' exact component={NewsDetails} />
            <Route path='/portfolio' exact component={ProjectGrid} />
            <Route path='/profile/:ID/' exact component={TeamDetails} />
          </Switch>
        </div>
        <ScrollToTop />
      </BrowserRouter>
    )
  }
}

export default Markup
