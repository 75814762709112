import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper, useLightbox } from 'simple-react-lightbox'
import Header from './../../Layout/HeaderMenu'
import Footer from './../../Layout/Footer'
import PageTitle from './../../Layout/PageTitle'

//images
import bnr1 from './../../../images/banner/bnr9.jpg'
import {
  box1,
  box2,
  box3,
  box4,
  box5,
  box6,
  box7,
  box8,
  box9,
  box10,
  box11,
  box12,
} from './ProjectFullWidth'
const imageBlog = [
  { Large: box1, name: 'Ankwil 2 Dam Spillway' },
  { Large: box3, name: 'Kurra Power House' },
  { Large: box4, name: 'Kurra Power House' },
  { Large: box5, name: 'Kurra Power Station Pipeline' },
  { Large: box6, name: 'Kurra PH Tailrace' },
  { Large: box7, name: 'Kurra Workshop' },
  { Large: box8, name: 'Kwall Pipeline and PH' },
  { Large: box9, name: 'Kwall Power Station' },
  { Large: box11, name: 'Ouree Dam Spillway' },
  { Large: box10, name: 'Ouree Dam Gates' },
  { Large: box12, name: 'Ouree Dam' },
  { Large: box2, name: 'Bukuru 66KV substation' },
]

//Light Gallery on icon click
const Iconimage = (props) => {
  const { openLightbox } = useLightbox()
  return (
    <Link
      to={'#'}
      onClick={() => openLightbox(props.imageToOpen)}
      className='mfp-link portfolio-fullscreen'
    >
      <i className='ti-fullscreen icon-bx-xs'></i>
    </Link>
  )
}

class PortfolioGrid4 extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <div className='page-content bg-white'>
          {/*  banner  */}
          <div
            className='dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt'
            style={{ backgroundImage: 'url(' + bnr1 + ')' }}
          >
            <PageTitle motherMenu='Our Portfolio' activeMenu='Our Portfolio' />
          </div>
          {/*  Section-1 Start  */}
          <div className='content-block'>
            <div className='section-full content-inner-2 portfolio text-uppercase'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-3'>
                   
                    <div className='sticky-top'>
                    <h3>MOTTO</h3>
                      <ul className='list-check primary'>
                        <li>Reliable Power you can Trust.</li>
                      </ul>
                      <h3>CORE VALUES</h3>
                      <ul className='list-check primary'>
                        <li>Team Work</li>
                        <li>Integrity, Fairness and Loyalty</li>
                        <li>Market Leader</li>
                        <li>Environmental and Safety Consciousness</li>
                        <li>Diversity and Inclusion</li>
                        <li>Customer Centric</li>
                        <li>Corporate Social Responsibility</li>
                        <li>Initiative and Drive for Productivity</li>
                      </ul>
                    </div>
                  </div>

                  <div className='col-md-9'>
                    <GalleryGrid />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    )
  }
}

function GalleryGrid() {
  return (
    <>
      <SimpleReactLightbox>
        <SRLWrapper>
          <ul className='row dlab-gallery-listing gallery-grid-4 gallery mfp-gallery port-style1'>
            {imageBlog.map((item, index) => (
              <li
                className='web design card-container col-lg-4 col-md-6 col-sm-6 p-a0'
                key={index}
              >
                <div className='dlab-box dlab-gallery-box'>
                  <div className='dlab-media dlab-img-overlay1 dlab-img-effect'>
                    <img src={item.Large} alt='' />
                    <div className='overlay-bx'>
                      <div className='overlay-icon align-b text-white text-left'>
                        <div className='text-white text-left port-box'>
                          <h5>{item?.name}</h5>
                          <p>{item?.description}</p>
                          <Iconimage />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </SRLWrapper>
      </SimpleReactLightbox>
    </>
  )
}
export { GalleryGrid }
export default PortfolioGrid4
