import React, { } from 'react'
import { Link } from 'react-router-dom'
import Header from '../Layout/HeaderMenu'
import Footer from '../Layout/Footer'
import PageTitle from '../Layout/PageTitle'
import axios from 'axios'
//Images
import bnr1 from './../../images/banner/bnr2.jpg'



const News = () => {
  const [fetchedNews, setFetchNews] = React.useState(null)

  React.useEffect(() => {
    const getData = async () => {
      const response = await axios.get('https://nesco-api-il7ir.ondigitalocean.app/api/v1/news/list?page=1&pageSize=2000');
      //  const response = await axios.get('https://nesco-api.herokuapp.com/api/v1/news/list?page=1&pageSize=2000');

      const activeNews = response?.data?.data?.news.filter(n => {
        return n.active === true;
      })

      if (activeNews.length) {
        setFetchNews(activeNews)
      }
    };

    getData()
  }, [])

  return (
    <>
      <Header />
      <div className='page-content bg-white'>
        {/* <!-- inner page banner --> */}
        <div
          className='dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt'
          style={{ backgroundImage: 'url(' + bnr1 + ')' }}
        >
          <PageTitle motherMenu='News' activeMenu='News' />
        </div>
        {/* <!-- inner page banner END --> */}
        {/* <!-- contact area --> */}
        <div className='content-area'>
          <div className='container max-w900'>
            <NewsPost fetchedNews={fetchedNews} />
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}
export default News

function NewsPost({ fetchedNews }) {
  return (
    <>
      {fetchedNews?.map((item, index) => (
        <div className='blog-post blog-md' key={index}>
          <div className='dlab-post-media dlab-img-effect zoom-slow'>
            <img src={item.cover_image} alt="" />
          </div>
          <div className='dlab-post-info'>
            <div className='dlab-post-title '>
              <h4 className='post-title'>
                <Link to={`/news-details/${item.id}`}>{item.title}</Link>
              </h4>
            </div>
            <div className='dlab-post-readmore blog-share'>
              <Link
                to={`/news-details/${item.id}`}
                title='READ MORE'
                rel='bookmark'
                className='site-button outline outline-1'
              >
                READ MORE
                <i className='fa fa-long-arrow-right m-l5'></i>
              </Link>
            </div>
          </div>
        </div>
      )) ?? <div className='blog-post blog-md' >No News Available.</div>}

      {/* {fetchedNews?.data?.news?.map((item,index)=>(
					<div className="item p-3" key={index}>
						<div className="blog-post blog-grid blog-rounded blog-effect1">
							<div className="dlab-post-media dlab-img-effect "> <Link 
							 to={`/news-details/${item.id}`}><img src={item.cover_image} alt="" /></Link> </div>
							<div className="dlab-info p-a20 border-1">
								<div className="dlab-post-title ">
									<h5 className="post-title font-weight-500"><Link  to={`/news-details/${item.id}`}>{item.title}</Link></h5>
								</div>
								<div className="dlab-post-meta ">
									<ul>
										<li className="post-date"> <i className="fa fa-comments"></i><strong>{moment(item.updatedAt).format('LL')}</strong></li>
									</ul>
								</div>
								<div className="dlab-post-readmore"> 
									<Link  to={`/news-details/${item.id}`} title="READ MORE" rel="bookmark" className="site-button-link black outline">READ MORE 
										<i className="ti-arrow-right"></i>
									</Link> 
								</div>
							</div>
						</div>
					</div>
				))}		 */}

      {/* <div>
        <div className="dlab-divider bg-gray-dark"></div>
            <div className="dlab-post-title">
                <h5 className="post-title m-t0 ">
                    METHOD OF APPLICATION
                </h5>
            </div>
          <p>
            Eligible candidates meeting the required qualifications and experience should submit their applications, under confidential <span className='font-weight-700'>Cover Letter</span>, either by post, courier or e-mail, enclosing <span className='font-weight-700'>Copies of Certificates</span>, detailed <span className='font-weight-700'>
              Curriculum Vitae (CV)
            </span> with names, postal and e-mail addresses and telephone numbers of <span className='font-weight-700'>Three (3) Traceable Referees</span> by <span className='font-weight-700'>31st October 2022a</span> to <span className='font-weight-700'>the Chairman of the Board of Directors</span> for <span className='font-weight-700'>Chief Accountant Position</span> and the <span className='font-weight-700'> Managing Director</span> for <span className='font-weight-700'>the Rest of the Positions</span> at the following <span className='font-weight-700'>address</span>;
          </p>
          <p className='font-weight-700'>Nigerian Electricity Supply Corporation (Nigeria) Limited,<br/>
          10 Barkin Ladi Road,<br/>
          P. O. Box 15,<br/>
          Bukuru, Jos South,<br/>
          Plateau State,<br/>
          Nigeria<br/>
          </p>
          <p className='font-weight-700'> With electronic copies to: E-mail Address - : info@nesconigeria.com</p>
          <p>Note:</p>
          <ul>
            <li>
              Those who already applied for the positions advertised above during the past one year do not need to apply again.
            </li>
            <li>
              Only shortlisted applicants will be acknowledged and invited for interviews.
            </li>
          </ul>
        </div> */}
    </>
  )
}
export { NewsPost }

