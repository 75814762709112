export const teamData = [
  {
    id: 1,
    name: 'ENGINEER EVANS EVERSON MSISKA',
    title: '(Managing Director), MBA, BSC, Eng, MIET, MIMechE, MMIE, REng',
    profile: [
      "Engineer Evans Everson Msiska has more than thirty-five (35) years extensive electricity utility experience in operations & maintenance, engineering, project management and general management. Twenty-five (25) yearsexperience has been at management level and above level, mostly in various positions at Electricity Supply Corporation of Malawi Limited (ESCOM). He left ESCOM as Director of Generation. Through these various roles, he has over the years gained valuable knowledge and understanding of the operations and management of power utility companies.",
      "He was a Member of the Board of Directors of Electricity Generation Company (Malawi) Limited at the time of joining Nigerian Electricity Supply Corporation (Nigeria) Limited.",
      "Engineer Evans Everson Msiska holds a Bachelor of Science Degree in Mechanical Engineering from the University of the West of Scotland, United Kingdom, and a Master of Business Administration (MBA) from City Universitys Cass Business School in London, United Kingdom."
    ],
    additional: 'He is also a',
    additionalData: [
      'Chartered Engineer (CEng) with the Engineering Council of the United Kingdom.',
      'Member of the Institution of Engineering and Technology (MIET) in the United Kingdom.',
      'Member of the Institution of Mechanical Engineers (MIMechE) in the United Kingdom.',
      'Member of the Malawi Institution of Engineers (MMIE) in Malawi.',
      'Registered Engineer (REng) with the Malawi Board of Engineers in Malawi.',
      'Selected Registered Engineer (SREng) with the Malawi Board of Engineers.',
    ],
  },

  {
    id: 2,
    name: 'ENGR. MAURICE CHILAKA OKORO',
    title: '(Chief Operations Officer)',
    profile:
      [
        'Engr. Okoro has forty seven years of experience in the field of Electrical Power Engineering. He has been Head, respectively, of the Generation and Distribution departments in the past thirty nine years. He holds a Bachelor of Science degree in Electrical Engineering, and is a Chartered Engineer, and Member of the Nigerian Society of Engineers.',
        'He has served in various Governmental bodies including Plateau State Energy Committee, and the Technical Working Group of the Nigerian Electricity Supply and Installation Standards Regulations.'
      ]
  },

  {
    id: 3,
    name: 'MR. ABRAHAM ADAMA',
    title: 'Human Resources and Administration Manager',
    profile: [
      'Mr. Abraham has over 26 years of Experience with Nigerian Electricity Supply Corporation (NNL) Bukuru.  He has been head of Billing and debt recovery. He was promoted to Deputy Personnel Manager in 2011.  A post he held until 2020.  In 2021 He became the Head of Human Resources & Administration.',
      'He holds a Masters of Administration and Management from Abubakar Tafawa Belewa University, and is a Member of Nigerian Institute of Management.'
    ]
    ,
  },
  // {
  //   id: 4,
  //   name: 'Mr. LUKA RWANG DASHWEI',
  //   title: 'Acting Chief Accountant',
  //   profile: [
  //     'Mr Luka Rwang Dashwei is an accountant by profession, he has worked for over 20years in the public and private sector, Mr Dashwei holds HND in accounting, PGD IN MANAGEMENT and a MASTERS IN FINANCE (MBAfin).',
  //     'He is a chartered accountant with two bodies (ANAN &CICMA) and is the present acting Chief Accountant of NNL.'
  //   ],
  // },
  // {
  //   id: 4,
  //   name: 'Mrs. CHRISTIANA L. KEHINDE',
  //   title: 'Chief Accountant',
  //   profile:[
  //     'She is an indigene of Ekiti State and an alumnus of Federal Polytechnic Bauchi.  Mrs. Kehinde joined NESCO as an Assistant Accountant in August, 1994.  She was the import Manager, Insurance Officer and currently the Chief Accountant of the Company.',
  //     'A fellow of Institute of Chartered Accountants of Nigeria (ICAN) she has 27 years experience in accounting field and financial need of the Company.'
  //   ],
  // },
  // {
  //   id: 5,
  //   name: 'JOHN USMAN MCCARTHY',
  //   title: 'Head of Stores and Procurement',
  //   profile: [
  //     'John has about 22 years in Stores Management and Procurement.  He holds a Bachelor degree in Education Administration and Planning.  He is a fellow: Accounts/Audit Forensic, Senior Fellow: Forensic studies and certified forensic expert.'
  //   ],
  // },

  // {
  //   id: 6,
  //   name: 'ENGR. AZU IOREMBER MISHAEL',
  //   title: '(Asst. Chief Engineer Generation)',
  //   profile: [
  //     'Azu has more than twenty-six years of experience in the field of Electrical Engineering work comprising installation, circuit designs, protection, measurement, instrumentation, system control and general troubleshooting. He has versatile knowledge in alternator repairs, maintenance and protection with added advantage of good mechanical skills in hydro turbines.',
  //     'Azu holds a Bachelor of Engineering Degree and is registered with the Council for the Regulation of Engineering in Nigeria (COREN).  Azu has attended several workshops on topical areas encompassing Industrial safety system optimization, troubleshooting techniques, leadership and team building among many.',
  //     'Azu has been working with NESCO Nigeria Limited for more than twenty years and is responsible for Managing Electrical Generation, Protection, control as well as measurement and general plant maintenance.'
  //   ]
  // },

  {
    id: 7,
    name: 'Col Joe Miner Rd',
    title: 'Commercial Officer',
    profile: [
      "Joe Miner is a decorated army officer transitioning from the Nigerian Army - - tour of duty ended June 2003.Joe is a dedicated professional with more than 23 years’ experience in the Nigerian Army logistics. He has held several positions in command and on the staff. He has had extensive work in information and communication technology (over 16years). Qualifications include a LL. B in Law, comprehensive field training; and extensive experience in law, safety, supply and transportation.",
      "A highly experienced former chief executive of Microwave Associates Nigeria Limited, who has demonstrated the ability to lead diverse teams of professionals to new levels of success in a variety of highly competitive industries, cutting-edge markets, and fast-paced environments. Strong technical and business qualifications/experience with an impressive track record of many years of hands-on experience in strategic planning, business unit development, project and product management, and system engineering strategies.",
      "Joe has a proven ability to successfully analyze an organization's critical business requirements, identify deficiencies and potential opportunities, and develop innovative and cost-effective solutions for enhancing competitiveness, increasing revenues, and improving customer service offerings."
    ]
  },
  {
    id: 8,
    name: 'Engr. YIMBA TERUNGWA EMMANUEL',
    title: 'Assistant Chief Engineer (Services)',
    profile: [
      'Engr. Yimba is a COREN registered Electrical  Engineer with good Project Management and procurement  skills, Cognate technical, safety and management proficiency  in electricity Distribution Networks in high and low voltages including installations, construction, maintenance and operations, as well as revenue protection and energy management.  successful at coordinating diverse energy metering projects, improving Marketing/sales while managing  customer relationship as well as departmental cohesion and processes for optimizing  performance.',
      'Engr. Yimba holds a bachelor’s Degree in Electrical and Electronics Engineering as well as a Post graduate diploma in Management(PGDM) and a Masters of Business Administration (MBA) all from Sarwuan Tarkaar University Formerly know as University of  Agriculture Makurdi, he has  worked with Jos Electricity Distribution PLC at different capacities and positions as a Marketer, Network Engineer (Distribution Manager), Feeder manager before joining the Nigerian Electricity Supply Corporation(Nigeria) Limited',
      'He is  a member of The Nigerian Society of Engineers, (NSE).,Member Nigerian institution of electrical and Electronics Engineers (NIEEE) Member Nigerian institution of power Engineers (NIPE), Member Nigerian Institute Management (NIM)'
    ],
  },
  {
    id: 9,
    name: 'Engr. TUNDE AYANSOLA',
    title: 'Planning and development manager',
    profile: [
      'Engr. Ayansola have 29 years of experience in extensive electricity utility management, operations & maintenance, engineering, project and production management.',
      "His career span across the following companies gaining vast experience in Genesis power and Energy limited in power and energy solution, business development, operations and management of power utility.Production management, maintenance and operations management at Stonecraft FZE.At Pamol Nigeria limited(a subsidiary of Dunlop plc) Utilities management, plant maintenance and general engineering management.",
      'He holds a(BSc) in Agricultural Engineering(UI), MEng Mechanical Engineering(Uniyo), EMBA(Unical) and Project Management College UK(EMPM).He is a Chartered Engineer, and Member of the Nigerian Society of Engineers.Also a Chartered Arbitrator(MCArb) and Associate member of Nigerian Institute of management.'
    ]
  },
  {
    id: 10,
    name: 'SUNDAY SAMUEL KARGWAK',
    title: '(Chief Accountant), ACA, MBF and HND',
    profile: [
      'Mr. Sunday Samuel kargwak is a certified chartered accountant a member of the institute of chartered accountant(ICAN). He also has a postgraduate diploma from Nigeria college of Accountancy kwall jos plateau state (ANAN). He upholds a mastered degree in banking and finance from the prestigious Ahamdu Bello university Zaria and a Higher National diploma in Accountancy plateau polytechnic Barki Ladi Jos.',
      "In his fourteen years progressive financial and administrative management experience he has led the development and implementation of robust procurement and contact management. systems process management, financial planning, budgeting and financial reporting. He has been helpful for statutory returns and renewals audit analysis.",
      'Sunday Samuel work with Macmillan Nigeria ltd, Grand cereal Nigeria ltd as factory accountant and Benybite foods Nigeria ltd as finance and Admin manager.',
      'He is proficient with several accounting packages including sage line 500 sage line 50 SAP and Aerchain service.'
    ]
  },
  {
    id: 11,
    name: 'MUHAMMAD SANI YAHAYA',
    title: 'Generation Manager',
    profile: [
      'Engr. Muhammad Sani Yahaya is an electrical engineer who has a vast experience in various power systems across the value chain and proficient in skills spanning across; project development and execution, contract management, customer management strategies and off-grid solar system designs and installations.',
      "Prior to his appointment as generation manager at Nigerian Electricity Supply Corporation (NESCO), Engr. Muhammad Sani Yahaya had gained experience in power system Generation, Transmission and Distribution across different power generation fleets, among which are Aeroderivative power turbine with hands-on experience trainings by major OEM’s.",
      'He has a very strong technical competency in hydro turbines operations and maintenance having undergone a comprehensive training at Kainji Hydroelectric power station (Mainstream Energy Solution LTD). H also obtained his trainings and certifications on operations and maintenance in power systems at National Power Training Institute of Nigeria (NAPTIN). His most recently held positions  are instrumentation and control Engineer at 210MW gas power plant at Dangote cement plant Obajana and an Assistant Manager protection control and metering at Kano Electricity Distribution Company (KEDCO).',
      'Engr. Muhammad Sani Yahaya obtained his bachelor’s degree ( B.Eng) in Electrical Engineering from Aliko Dangote University of Science and Technology (ADUSTEC) fomer  Kano University of Science and Technology Wudil (KUST)  he also has a masters degree (M.Eng) in industrial maintenance Engineering from Global Wealth University fomerly known as international Institute of Technology and Management.',
      'Engr. Muhammad Sani Yahaya is an active member of several professional bodies which includes COREN, NSE and NIEEE.'
    ]
  },
]
