import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../Layout/HeaderMenu'
import Footer from '../Layout/Footer'
import HomeOwlSlider from '../Element/HomeOwlSlider'
import GoogleMaps from 'simple-react-google-maps'
import axios from 'axios'
import ExploreCarousel from '../Element/ExploreCarousel'

import LatestBlogSlider from './../Element/LatestBlogSlider';

import bg17 from './../../images/background/bg17.jpg'
import bnrnew from './../../images/background/bg25.jpg'
import bg24 from './../../images/background/bg2.jpg'
import about12 from './../../images/about/pic12.jpg'

import pic1 from './../../images/our-team/pic1.jpg'
import pic2 from './../../images/our-team/pic2.jpg'
import pic3 from './../../images/our-team/pic3.jpg'
// import pic4 from './../../images/our-team/pic4.jpg'
// import pic5 from './../../images/our-team/pic5.jpg'
// import pic7 from './../../images/our-team/pic7.jpg'
// import pic8 from './../../images/our-team/pic8.jpg'
import pic12 from './../../images/our-team/pic12.jpg'
import pic13 from './../../images/our-team/pic13.jpg'
import pic14 from './../../images/our-team/pic14.jpg'
import pic15 from './../../images/our-team/pic15.jpg'


const teamBlog = [
  { images: pic1, name: 'Engr Msiska', title: '(Managing Director)', id: 1 },
  { images: pic3, name: 'Engr Okoro', title: '(Chief Operations Officer)', id: 2 },
  { images: pic2, name: 'Mr Abraham', title: '(Human Resources and Administration Manager)', id: 3 },
  // { images: pic4, name: 'Mr Luka Rwang Dashwei', title: '(Acting Chief Accountant)', id: 4 },
  // { images: pic5, name: 'Mr McCarthy', title: '(Head of Stores and Procurement)', id: 5 },
  // { images: pic7, name: 'Engr Azu', title: '(Asst. Chief Engineer Generation)', id: 6 },
  // { images: pic8, name: 'Col Joseph Miner RTD', title: '(Commercial Officer)', id: 7 },
  { images: pic12, name: 'Engr. Yimba Terungwa Emmanuel', title: '(Assistant Chief Engineer Services)', id: 8 },
  { images: pic13, name: 'Engr. Tunde Ayansola', title: '(Planning and development manager)', id: 9 },
  { images: pic14, name: 'Sunday Samuel kargwak', title: '(Chief Accountant)', id: 10 },
  { images: pic15, name: 'Engr. Muhammad Sani Yahaya', title: 'Generation Manager', id: 11 },
]

const Index5 = () => {
  const [fetchedNews, setFetchNews] = React.useState(null)
  React.useEffect(() => {
    const getData = async () => {
      const response = await axios.get('https://nesco-api-il7ir.ondigitalocean.app/api/v1/news/list?page=1&pageSize=2000');
      //  const response = await axios.get('https://nesco-api.herokuapp.com/api/v1/news/list?page=1&pageSize=2000');

      const activeNews = response?.data?.data?.news.filter(n => {
        return n.active === true;
      })

      if (activeNews.length) {
        setFetchNews(activeNews)
      }
    };

    getData()

    var i = 0
    // Placeholder Animation Start
    var inputSelector = document.querySelectorAll('input, textarea')

    for (i = 0; i < inputSelector.length; i++) {
      inputSelector[i].addEventListener('focus', function (event) {
        return this.parentElement.parentElement.classList.add('focused')
      })
    }

    for (i = 0; i < inputSelector.length; i++) {
      inputSelector[i].addEventListener('blur', function (event) {
        var inputValue = this.value
        if (inputValue === '') {
          this.parentElement.parentElement.classList.remove('filled')
          this.parentElement.parentElement.classList.remove('focused')
        } else {
          this.parentElement.parentElement.classList.add('filled')
        }
      })
    }
  }, [])


  return (
    <>
      <Header />

      <div className='page-content bg-white rubik-font'>
        <div className='owl-slider-banner main-slider'>
          <HomeOwlSlider />
        </div>
        <div className='content-block'>
          {/* <!-- About Us --> */}
          <div
            className='section-full industry-service'
            style={{ backgroundImage: 'url(' + bg17 + ')' }}
          >
            <div className='container'>
              <div className='row m-b80'>
                <div className='col-lg-4 col-md-4'>
                  <div className='icon-bx-wraper ind-ser-bx'>
                    <div className='icon-lg m-b10'>
                      <Link to={'#'} className='icon-cell text-primary'>
                        <i className='flaticon-operation'></i>
                      </Link>
                    </div>
                    <div className='icon-content'>
                      <h3 className='dlab-tilte'>Customer Satisfaction</h3>
                      <p>
                        Successfully provided steady and continuous
                        electricity supply to customers for over 90 years.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-4'>
                  <div className='icon-bx-wraper ind-ser-bx active'>
                    <div className='icon-lg m-b10'>
                      <Link to={'#'} className='icon-cell text-primary'>
                        <i className='flaticon-network'></i>
                      </Link>
                    </div>
                    <div className='icon-content'>
                      <h3 className='dlab-tilte'>True Partners</h3>
                      <p>
                        Licensed by Federal Ministry of Power and Steel to supply new and existing customers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-4'>
                  <div className='icon-bx-wraper ind-ser-bx'>
                    <div className='icon-lg m-b10'>
                      <Link to={'#'} className='icon-cell text-primary'>
                        <i className='flaticon-mind'></i>
                      </Link>
                    </div>
                    <div className='icon-content'>
                      <h3 className='dlab-tilte'>Focus On Innovation</h3>
                      <p>
                        Actively researching new technologies and pursuing new
                        opportunities to improve electricity supply.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row d-flex align-items-center'>
                <div className='col-lg-5 col-md-12 m-b30'>
                  <h2 className='box-title m-t0 m-b20 font-40'>
                    <span className='font-weight-400'>About </span>
                    <br />
                    Our Company
                  </h2>
                  <p className='m-b20'>
                    Nigerian Electricity Supply Corporation (Nigeria) LTD is a
                    Power utility company registered in Nigeria and licensed
                    to generate, transmit and distribute electricity within
                    its franchise areas in Plateau, Benue, Nassarawa and parts
                    of the Federal Capital Territory.
                  </p>
                  <Link to={'./about'} className='site-button button-md'>
                    Read More
                  </Link>
                </div>
                <div className='col-lg-7 col-md-12'>
                  <img src={about12} className='radius-sm' alt='' />
                </div>
              </div>
            </div>
          </div>
          {/* <!-- About Us End --> */}
          {/* <!-- News --> */}
          <div className='section-full bg-blue-light content-inner explore-projects'>
            <div className='container'>
              <div className='section-content'>
                <div className='row'>
                  <div className='col-md-12 col-lg-12 section-head text-center'>
                    <h2 className=' font-40'>
                      <span className='font-weight-400'></span> News
                    </h2>
                  </div>
                  <div className='col-md-12 col-lg-12 section-head'>
                    {
                      fetchedNews ?
                        <LatestBlogSlider news={fetchedNews} />
                        :
                        <div className="section-full content-inner">
                          <div className="container">
                            <div className="max-w600 m-auto text-center m-b30">
                              <h6 className="m-t0">No News Available</h6>
                            </div>
                          </div>
                        </div>
                    }
                  </div>
                </div>
                {
                  fetchedNews && <div className='dlab-post-readmore blog-share'>
                    <Link
                      to={`/news`}
                      title='READ MORE'
                      rel='bookmark'
                      className='site-button outline outline-1'
                    >
                      View All News
                      <i className='fa fa-long-arrow-right m-l5'></i>
                    </Link>
                  </div>
                }
              </div>
            </div>
          </div>
          {/* <!-- News End --> */}
          {/* <!-- Why Chose Us --> */}
          <div className='section-full  content-inner explore-projects'>
            <div className='container'>
              <div className='section-content'>
                <div className='row'>
                  <div className='col-md-12 col-lg-12 section-head text-center'>
                    <h2 className='m-b0 font-40'>
                      <span className='font-weight-400'>Explore</span>{' '}
                      Projects
                    </h2>
                    <p className='m-b0'>
                      Take a look at our individual and collective entreprise
                      within the Plateau, Nasarawa, Benue, Kaduna and parts of
                      the FCT.
                    </p>
                  </div>
                </div>
                {/* <!-- blog post Carousel with no margin --> */}
                <div className=''>
                  <ExploreCarousel />
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Why Chose Us End --> */}
          <div
            className='section-full overlay-black-dark our-projects'
            style={{ backgroundImage: 'url(' + bnrnew + ')' }}
          >
            <div className='container text-white'>
              <div className='row m-lr0 d-flex align-items-stretch'>
                <div className='col-lg-4 col-md-4 p-lr0 d-flex ind-ser-info-bx'>
                  <div className='ind-service-info align-self-stretch'>
                    <span>01</span>
                    <div className='ind-service-info-in'>
                      <h2>
                        <span>World Class</span>Technology
                      </h2>
                      <p>
                        This diversification includes consultancy, building distribution and substation installations for governments and private owners. NESCO is always there to provide instant supply and distribution of power.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-4 p-lr0 d-flex ind-ser-info-bx'>
                  <div className='ind-service-info align-self-stretch'>
                    <span>02</span>
                    <div className='ind-service-info-in'>
                      <h2>
                        <span>Quality </span>Standard
                      </h2>
                      <p>
                        Some of the known success factors of the company are
                        the use of locally fabricated spare parts, a very
                        strong in-house maintenance culture and
                        people–oriented culture. The Company is committed to
                        providing its consumers with steady and continuous
                        electricity supply.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-4 p-lr0 d-flex ind-ser-info-bx'>
                  <div className='ind-service-info align-self-stretch'>
                    <span>03</span>
                    <div className='ind-service-info-in'>
                      <h2>
                        <span>Productive</span>Capacity
                      </h2>
                      <p>
                        The company is expanding its operations to supply Plateau and other neighboring States. Generating capacity would increase from the Company's current hydro capacity of 26MW and it is anticipated that additional hydro plants and other renewable energy sources would explored to yield higher energy capacity.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className='section-full p-tb15 our-support content-inner-2'
            style={{
              backgroundImage: 'url(' + bg24 + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100%',
              backgroundPosition: 'bottom',
            }}
          >
            <div className='container'>
              <div className='row'>
                <div className='col-md-12 col-lg-12 section-head text-center'>
                  <h2 className='m-b0 font-40'>
                    <span className='font-weight-400'>Contact</span> Us
                  </h2>
                  <p className='m-b0'>
                    Contact our support team to make inquiries about our
                    solutions and coverage areas.
                  </p>
                </div>
              </div>
              <div className='support-box-form bg-primary'>
                <div className='row m-lr0'>
                  <div className='col-lg-6 p-lr0 d-flex'>
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d57803.76927259502!2d75.78311389999999!3d25.110810700000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1540556893926" style={{width: "100%", border: "0"}} className="d-flex align-items-stretch" allowfullscreen></iframe> */}
                    <GoogleMaps
                      apiKey={'AIzaSyDrAU41UTBlcEDNJgEtdlFLZeUBNBuHhzM'}
                      style={{ minHeight: '100%', width: '100%' }}
                      zoom={6}
                      center={{ lat: 9.814646, lng: 8.873779 }}
                      markers={{ lat: 9.814646, lng: 8.873779 }} //optional
                    />
                  </div>
                  <div className='col-lg-6'>
                    <div className='support-form'>
                      <div className='support-title text-white m-b30'>
                        <h6 className='text-uppercase font-weight-500 m-b10'>
                          Support
                        </h6>
                        <h2 className='font-40 font-weight-400 m-tb0'>
                          Need Help?
                        </h2>
                        <p className='font-14'>
                          Contact our customer support team if you have any
                          questions.
                        </p>
                      </div>
                      <div className='dezPlaceAni'>
                        <div className='dzFormMsg'></div>
                        <form method='post' className='' action=''>
                          <input
                            type='hidden'
                            value='Contact'
                            name='dzToDo'
                          />
                          <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                              <div className='form-group'>
                                <div className='input-group'>
                                  <label>Your Name</label>
                                  <input
                                    name='dzName'
                                    type='text'
                                    required
                                    className='form-control'
                                    placeholder=''
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                              <div className='form-group'>
                                <div className='input-group'>
                                  <label>Phone</label>
                                  <input
                                    name='dzOther[Phone]'
                                    type='text'
                                    required
                                    className='form-control'
                                    placeholder=''
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                              <div className='form-group'>
                                <div className='input-group'>
                                  <label>Your Email Address</label>
                                  <input
                                    name='dzEmail'
                                    type='email'
                                    className='form-control'
                                    required
                                    placeholder=''
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                              <div className='form-group'>
                                <div className='input-group'>
                                  <label>Your Message...</label>
                                  <textarea
                                    name='dzMessage'
                                    rows='4'
                                    className='form-control'
                                    required
                                    placeholder=''
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                              <button
                                name='submit'
                                type='submit'
                                value='Submit'
                                className='site-button white button-md m-t10'
                              >
                                Submit Now
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Team Section --> */}
          <div className='section-full text-center bg-white content-inner-1'>
            <div className='container'>
              <div className='section-head text-black text-center'>
                <h2>Management Team</h2>
              </div>
              <div className='row d-flex justify-content-center'>
                {teamBlog.map((data, index) => (
                  <div
                    className='col-lg-3 col-md-6 col-sm-6 m-b5 mb-3'
                    key={index}
                  >
                    <div className='dlab-box'>
                      <div
                        style={{ height: '22.8rem', width: '' }}
                        className='dlab-media dlab-img-overlay6 dlab-img-effect radius-sm'
                      >
                        <img
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                          src={data.images}
                          alt=''
                        />
                        <div className='overlay-bx'>
                          <div className='overlay-icon'>
                            <Link
                              to={`/profile/${data.id}`}
                              className='site-button p-2 white button-lg'
                            >
                              See Profile
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className='dlab-title-bx p-a10'>
                        <h5 className='text-black m-a0'>{data?.name}</h5>
                        <span className='clearfix'>{data?.title}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Index5
